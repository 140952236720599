import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import {GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
// import instagram from "../img/social/instagram.svg";
// import linkedin from "../img/social/linkedin.svg";
// import mail from "../img/social/mail.svg";

// eslint-disable-next-line
export const AboutPageTemplate = ({ title, content, contentComponent, image }) => {
  const PageContent = contentComponent || Content;
  const Image = getImage(image) || image;

  return (
    <section className="section has-background-primary">
      <div className="container">
          <div className="content">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light line-after">
              <span>{title}</span>
            </h1>
            <div className="columns is-12">
              <div className="column">
              <div className="columns">
                  <div className="column">
                    <article>
                      <GatsbyImage image={Image} alt="profile picture"/>
                    </article>
                  </div>
                  <div className="column">
                      <article>
                        <PageContent className="content" content={content} />
                        <br/>
                          <Link className="btn is-pulled-right" to="/resume">
                            Resume →
                          </Link>
                      </article>
                        <br/>
                        <br/>
                        <br/>
                        {/* <div className="columns is-centered">
                            <div className="social column has-text-centered social_about">
                              <Link to="/contact">
                                <img
                                  src={mail}
                                  alt="mail"
                                  style={{ width: "1.2em", height: "1.2em" }}
                                />
                              </Link>
                              <a title="instagram" href="https://www.instagram.com/creativity_abyss/">
                                <img
                                  src={instagram}
                                  alt="Instagram"
                                  style={{ width: "1.2em", height: "1.2em" }}
                                />
                              </a>
                              <a title="linkedin" href="https://www.linkedin.com/in/zainab-tariq/">
                                <img
                                  src={linkedin}
                                  alt="linkedin"
                                  style={{ width: "1.2em", height: "1.2em" }}
                                />
                              </a>
                            </div>
                          </div> */}
                    </div>
                   </div> 
                  </div> 
                </div>
              </div>
          </div>
    </section>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        image={post.frontmatter.image}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
             gatsbyImageData(width: 526, quality: 92, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;